import { IPlan, ISite } from "@ehabitation/ts-utils/browser";
import { LoadingWrapper, Modal } from "@ehabitation/ui";
import { saveCurrentTasksState } from "Components/Menu/thunks";
import {
  applyNewThresholdsToPlan,
  clickToSeeThresholds,
  simulateConfirmation,
  simulateUsingPlanThresholds,
  thresholdAlertBeforeSimulate,
} from "Components/Plan/TaskList/constants";
import { logSimulateBase } from "helpers/analytics";
import { useCheckPlanHash } from "hooks/useCheckPlanHash";
import { useTriggerSimulation } from "hooks/useTriggerSimulation";
import React, { FC, useState } from "react";
import { FiAlertTriangle } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "store";
import { selectTasksUpdating } from "store/ui";
import { FaSpinner } from "react-icons/fa"; // Import spinner icon
import { useSimulation } from "../hooks";
import { SimulationResult } from "helpers";
import { EndDatesTable } from "../components/EndDatesTable";

const renderRiskIntervalResults = (results: any) => {
  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Percentiles</th>
            <th>Finish Date</th>
            <th>Weather Days</th>
            <th>Tasks Impacted</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(results).map(([key, value]) => (
            <tr key={key}>
              <td>{key}</td>
              <td>{(value as { finishDate: string }).finishDate}</td>
              <td>{(value as { weatherDays: number }).weatherDays}</td>
              <td>{(value as { tasksImpacted: number }).tasksImpacted}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const renderFinishDistribution = (distribution: any) => {
  return (
    <div>
      <strong>
        Finish Distribution - IS THIS THE FIRST DATE OF THE WHOLE SIMULATION?
      </strong>
      <ul>
        {distribution.map((item: any, index: number) => (
          <li key={index}>
            <strong>Date:</strong> {item.date} - <strong>Count:</strong>{" "}
            {item.count}
          </li>
        ))}
      </ul>
    </div>
  );
};

export const SimulationView: FC<{
  syncThresholds: (projectId: string, planId: string) => void;
  projectId: string;
  plan?: IPlan;
  site: ISite;
  simulationInProgress: boolean;
  simulationResult?: SimulationResult;
}> = ({
  syncThresholds,
  projectId,
  plan,
  site,
  simulationInProgress,
  simulationResult, // Receive simulationResult
}) => {
  const tasksUpdating = useAppSelector(selectTasksUpdating);

  const { minorHashDrift, majorHashDrift } = useCheckPlanHash(
    site.project,
    plan?.id
  );

  const matrixDrift = minorHashDrift || majorHashDrift;

  const { triggerSimulation } = useTriggerSimulation();

  const [syncPlanToRiskMatrixRadio, setSyncPlanToRiskMatrixRadio] =
    useState("");
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSyncPlanToRiskMatrixRadio(event.target.value);
  };

  return (
    <>
      <div className="p-4 flex flex-col justify-top h-full overflow-auto">
        {/* Check if plan is provided */}
        {!plan && (
          <div className="text-2xl text-red-500">
            Upload, or select, a plan to run a simulation.
          </div>
        )}
        {plan && (
          <>
            {(matrixDrift || tasksUpdating) && (
              <>
                <div className="text-2xl">
                  <div className="flex gap-2 items-center text-2xl">
                    <FiAlertTriangle className="text-2xl text-gray-400 shrink-0" />{" "}
                    <label className="ml-2 text-2xl">
                      {thresholdAlertBeforeSimulate}
                    </label>
                    <label className="ml-2 text-2xl underline">
                      <NavLink to={"../settings/thresholds"}>
                        <span>{clickToSeeThresholds}</span>
                      </NavLink>
                    </label>
                  </div>
                </div>
                <div className="flex flex-col mt-8">
                  <div className="ml-2 flex items-center">
                    <input
                      type="radio"
                      name="syncPlanToRiskMatrixRadio"
                      id="overwriteThresholds"
                      value="true"
                      onChange={handleRadioChange}
                    />
                    <label
                      htmlFor="overwriteThresholds"
                      className="ml-2 text-2xl"
                    >
                      {applyNewThresholdsToPlan}
                    </label>
                  </div>
                  <div className="ml-2 flex items-center mt-6 mb-8">
                    <input
                      type="radio"
                      name="syncPlanToRiskMatrixRadio"
                      id="ignoreThresholds"
                      value="false"
                      onChange={handleRadioChange}
                    />
                    <label htmlFor="ignoreThresholds" className="ml-2 text-2xl">
                      {simulateUsingPlanThresholds}
                    </label>
                  </div>
                </div>
              </>
            )}
            <div className="text-2xl">
              {simulationInProgress
                ? `Simulating plan ${
                    plan?.title
                  } uploaded on ${plan?.createdAt?.toLocaleDateString()}.`
                : simulateConfirmation}
            </div>

            <div className="flex justify-end">
              <button
                className={`bg-[#000] text-[#FFF] rounded-full cursor-pointer text-2xl px-12 py-3 flex gap-2 items-center ${
                  tasksUpdating ||
                  (matrixDrift && syncPlanToRiskMatrixRadio === "") ||
                  simulationInProgress
                    ? "disabled:bg-gray-300 disabled:text-gray-400"
                    : ""
                }`}
                onClick={async () => {
                  if (syncPlanToRiskMatrixRadio === "true") {
                    await syncThresholds(projectId!, plan?.id!);
                  }
                  saveCurrentTasksState(plan?.id);
                  logSimulateBase(site.id, plan!.id);
                  await triggerSimulation(plan!.id);
                  setSyncPlanToRiskMatrixRadio("");
                }}
                disabled={
                  tasksUpdating ||
                  (matrixDrift && syncPlanToRiskMatrixRadio === "") ||
                  simulationInProgress ||
                  !plan
                }
              >
                {simulationInProgress || tasksUpdating ? (
                  <>
                    <FaSpinner className="animate-spin" /> Simulating
                  </>
                ) : (
                  "Simulate"
                )}{" "}
                {/* Show spinner when simulating or tasks are updating */}
              </button>
            </div>

            {simulationResult && (
              <>
                <hr className="mt-20 mb-20" />
                <h2 className="mb-10">Simulation results</h2>
                <div className="flex justify-start">
                  {" "}
                  {/* Ensure this is set to justify-start */}
                  <div className="w-1/5 flex items-center justify-center mr-4">
                    <NavLink
                      to={"../plan"}
                      className="hidden sm:inline-block text-black py-[0.4rem] px-8 underline hover:text-black active:relative active:top-[1px]"
                    >
                      View summary report
                    </NavLink>
                  </div>
                  <div className="w-4/5">
                    {" "}
                    {/* Use full width for the table */}
                    <EndDatesTable
                      simulationPlanResult={simulationResult.planResults}
                      enableSubColumn2={false}
                    />
                  </div>
                  
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
