import { CollectionType } from "@ehabitation/ts-utils/browser";
import config from "config";
import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { db } from "firebaseConfig";
import { ExportTypes, logExportEvent } from "helpers/analytics";
import React, { ChangeEvent, useEffect, useState } from "react";
import { downloadReference, handleGetFile } from "./helpers";
import { getPlan } from "helpers";

export const useExport = (orgId: string, siteId: string, planId: string, p6AssociatedCalendars?: boolean) => {
  const [selectedFormat, setSelectedFormat] = useState("acumen");
  const [isExporting, setIsExporting] = useState(false);
  const [fileName, setFileName] = useState("Risk Calendar");
  const [error, setError] = useState("");
  const [existingAcumenRiskRegister, setExistingAcumenRiskRegister] =
    useState<File>();
  const [
    uploadedExistingRiskRegisterFilename,
    setUploadedExistingRiskRegisterFilename,
  ] = useState<string>();
  const [unknownAcumenRiskRegisterFile, setUnknownAcumenRiskRegisterFile] =
    useState<boolean>(false);
  const [previousAcumenFileAvailablePre, setPreviousAcumenFileAvailablePre] =
    useState<boolean>(false);
  const [previousAcumenFileAvailablePost, setPreviousAcumenFileAvailablePost] =
    useState<boolean>(false);
  const [acumenFilenamePre, setAcumenFilenamePre] = useState<string>();
  const [acumenFilenamePost, setAcumenFilenamePost] = useState<string>();

  const handleSetFileName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileName = e.target.value;
    setFileName(fileName);
  };

  const submitForm = async () => {
    if (!fileName) return;
    if (!planId) return;

    setIsExporting(true);
    console.info({siteId, planId})
    try {
      await handleGetFile(
        siteId,
        planId,
        selectedFormat,
        fileName,
        uploadedExistingRiskRegisterFilename,
        p6AssociatedCalendars
      );

      setIsExporting(false);
    } catch (err) {
      handleError(err);
    }
  };

  useEffect(() => {
    if (!planId) return;
    const unsubscribe = onSnapshot(
      doc(db, CollectionType.Plans, planId),
      (snapshot) => {
        const plan = snapshot.data();
        const files = plan?.generatedFiles;

        const filenamePre = files?.acumenRiskXlsxPreMitigation;
        setPreviousAcumenFileAvailablePre(
          !!filenamePre && filenamePre != "pending"
        );
        setAcumenFilenamePre(filenamePre);

        const filenamePost = files?.acumenRiskXlsxPostMitigation;
        setPreviousAcumenFileAvailablePost(
          !!filenamePost && filenamePost != "pending"
        );
        setAcumenFilenamePost(filenamePost);
      }
    );
    return unsubscribe;
  }, [planId]);

  const downloadAcumenFile = async (acumenFilename: string) => {
    if (!acumenFilename || acumenFilename === "pending") return;

    try {
      const storage = getStorage();
      const storageRef = ref(storage, acumenFilename);
      logExportEvent({
        planId,
        siteId,
        exportType: ExportTypes.AcumenRiskCalendar,
      });
      await downloadReference(
        storageRef,
        fileName,
        "xlsx",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
    } catch (err) {
      handleError(err);
    }
  };

  const handleError = (err: any) => {
    console.error(err);
    setError("There was an error exporting this file, please try again.");
    setIsExporting(false);
  };

  const handleFile = async (
    event: ChangeEvent<HTMLInputElement>,
    setUnknown: Function,
    setFile: Function,
    setUploadedFilename: Function
  ) => {
    if (
      !event ||
      !event.target ||
      !event.target.files ||
      event.target.files?.length == 0
    ) {
      setUploadedFilename(null);
      setFile(null);
      return; //error or cancellation
    }
    const file = event.target.files[0];
    const fileType = file.name.split(".").at(-1)?.toLocaleUpperCase();
    setUnknown(fileType !== "XLSX");
    setFile(file);

    const fileName = `${siteId}_${planId}_acumen_${new Date().getTime()}.xlsx`;
    const storage = getStorage(undefined, `gs://${config.CACHE_BUCKET}`);

    const result = await uploadBytes(
      ref(
        storage,
        `client_data/${orgId}/weather_calendars/acumen/existing_risk_registers/${fileName}`
      ),
      file!
    );

    setUploadedFilename(fileName);
    return fileName;
  };

  const handleAcumenRiskRegisterFile = (event: ChangeEvent<HTMLInputElement>) =>
    handleFile(
      event,
      setUnknownAcumenRiskRegisterFile,
      setExistingAcumenRiskRegister,
      setUploadedExistingRiskRegisterFilename
    );

  return {
    error,
    fileName,
    isExporting,
    selectedFormat,
    handleSetFileName,
    setSelectedFormat,
    submitForm,
    downloadAcumenFile,
    acumenFilenamePre,
    acumenFilenamePost,
    previousAcumenFileAvailablePre,
    previousAcumenFileAvailablePost,
    setExistingAcumenRiskRegister,
    handleAcumenRiskRegisterFile,
    existingAcumenRiskRegister,
    uploadedExistingRiskRegisterFilename,
  };
};
