import { Modal, Spinner } from "@ehabitation/ui";
import { DownloadButton } from "Components/DownloadButton/DownloadButton";
import SiteWeatherDownloadSlide from "Components/HistoricalSiteStatsModal/SiteWeatherDownloadSlide";
import { downloadTaskExportData } from "Components/Menu/thunks";
import React, { useState } from "react";
import { AiOutlineDownload, AiOutlineFileExcel } from "react-icons/ai";
import { useAppDispatch } from "store";
import { IOrganisationSite } from "types";

interface InfoSectionsProps {
  siteId?: string;
  planId?: string;
  site?: IOrganisationSite;
}

const InfoSections: React.FC<InfoSectionsProps> = ({
  siteId,
  planId,
  site,
}) => {
  const dispatch = useAppDispatch();
  const [siteStatsModalOpen, setSiteStatsModalOpen] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

  return (
    <>
      <div className="rounded-lg border-gray-500 p-6">
        <p className="flex justify-between items-center">
          <h2>Time Risk Allowance - weather days </h2>
        </p>

        <p className="mb-2">
          Number of expected weather days, per month, based on the planned
          activities and dependencies.
        </p>
        <p className="mb-2">
          Min, max, mean and P-20, P-40, P-80, P-95 from 1000 simulations.
        </p>
      </div>
      <div className="flex justify-between">
        <DownloadButton
          onClick={async () => {
            setButtonDisabled(true);
            await dispatch(downloadTaskExportData(siteId, planId));
            setButtonDisabled(false);
          }}
          label="&nbsp;Activity&nbsp;Export"
          icon={buttonDisabled ? <Spinner size="xSmall"/> : <AiOutlineDownload className="inline" />}
          description="Excel spreadsheet (.xlsx) of all activities and their risk impacts."
          disabled={buttonDisabled}
        />
        <DownloadButton
          onClick={() => setSiteStatsModalOpen(true)}
          label="&nbsp;Site&nbsp;Statistics&nbsp;Report"
          icon={<AiOutlineFileExcel className="h-7 w-7" />}
          description="Historical stats of the current site."
        />
      </div>
      {siteStatsModalOpen && site && (
        <Modal
          id="site-stats-report"
          title="Site Stats Download"
          subTitle={site.name}
          handleCloseModal={() => setSiteStatsModalOpen(false)}
        >
          <SiteWeatherDownloadSlide
            handleCloseModal={() => setSiteStatsModalOpen(false)}
            site={site!}
            projectId={site.project}
          />
        </Modal>
      )}
    </>
  );
};

export default InfoSections;
