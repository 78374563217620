import { LoadingWrapper, Modal } from "@ehabitation/ui";
import { saveCurrentTasksState } from "Components/Menu/thunks";
import {
  applyNewThresholdsToPlan,
  clickToSeeThresholds,
  simulateConfirmation,
  simulateUsingPlanThresholds,
  thresholdAlertBeforeSimulate,
} from "Components/Plan/TaskList/constants";
import { logSimulateBase } from "helpers/analytics";
import { useTriggerSimulation } from "hooks/useTriggerSimulation";
import React, { FC, useState } from "react";
import { FiAlertTriangle } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "store";
import { selectTasksUpdating } from "store/ui";

export const SimulationModal: FC<{
  setOpenSimulate: (openSimulate: boolean) => void;
  syncThresholds: (projectId: string, planId: string) => void;
  projectId: string;
  planId: string;
  siteId: string;
  checkingMatrix: boolean;
  riskMatrixDrift: boolean;
}> = ({
  setOpenSimulate,
  syncThresholds,
  planId,
  siteId,
  projectId,
  checkingMatrix,
  riskMatrixDrift,
}) => {
  const tasksUpdating = useAppSelector(selectTasksUpdating);

  // const { isLoading: checkingMatrix, majorHashDrift, minorHashDrift } = useCheckPlanHash(
  //   projectId!,
  //   planId
  // );

  // const riskMatrixDrift = majorHashDrift || minorHashDrift;

  const { triggerSimulation } = useTriggerSimulation();

  const [syncPlanToRiskMatrixRadio, setSyncPlanToRiskMatrixRadio] =
    useState("");
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSyncPlanToRiskMatrixRadio(event.target.value);
  };

  return (
    <Modal
      id="risk-software"
      handleCloseModal={() => {
        setOpenSimulate(false);
        setSyncPlanToRiskMatrixRadio("");
      }}
      className="!h-[250px] w-full min-w-[600px]"
    >
      <LoadingWrapper loading={checkingMatrix} white>
        <div className="p-4 flex flex-col justify-between h-full">
          {(riskMatrixDrift || tasksUpdating) && (
            <>
              <div className="text-2xl">
                <div className="flex gap-2 items-center text-2xl">
                  <FiAlertTriangle className="text-2xl text-gray-400 shrink-0" />{" "}
                  <label className="ml-2 text-2xl">
                    {thresholdAlertBeforeSimulate}
                  </label>
                  <label className="ml-2 text-2xl underline">
                    <NavLink
                      to={"settings/thresholds"}
                      onClick={() => {
                        setOpenSimulate(false);
                      }}
                    >
                      <span>{clickToSeeThresholds}</span>
                    </NavLink>
                  </label>
                </div>
              </div>
              <div className="flex flex-col mt-8">
                <div className="ml-2 flex items-center">
                  <input
                    type="radio"
                    name="syncPlanToRiskMatrixRadio"
                    id="overwriteThresholds"
                    value="true"
                    onChange={handleRadioChange}
                  />
                  <label
                    htmlFor="overwriteThresholds"
                    className="ml-2 text-2xl"
                  >
                    {applyNewThresholdsToPlan}
                  </label>
                </div>
                <div className="ml-2 flex items-center mt-6 mb-8">
                  <input
                    type="radio"
                    name="syncPlanToRiskMatrixRadio"
                    id="ignoreThresholds"
                    value="false"
                    onChange={handleRadioChange}
                  />
                  <label htmlFor="ignoreThresholds" className="ml-2 text-2xl">
                    {simulateUsingPlanThresholds}
                  </label>
                </div>
              </div>
            </>
          )}
          <div className="text-2xl">{simulateConfirmation}</div>

          <div className="flex justify-end">
            <button
              className="cursor-pointer text-2xl font-medium px-2 py-3 flex gap-2 items-center disabled:text-gray-400 mr-10 underline"
              onClick={() => {
                setOpenSimulate(false);
                setSyncPlanToRiskMatrixRadio("");
              }}
              disabled={tasksUpdating}
            >
              Cancel
            </button>
            <button
              className="bg-[#000] text-[#FFF] rounded-full cursor-pointer text-2xl px-12 py-3 flex gap-2 items-center disabled:text-gray-400"
              onClick={() => {
                (async () => {
                  if (syncPlanToRiskMatrixRadio === "true") {
                    await syncThresholds(projectId!, planId!);
                  }
                  saveCurrentTasksState(planId);
                  setOpenSimulate(false);
                  logSimulateBase(siteId, planId);
                  triggerSimulation(planId);
                  setSyncPlanToRiskMatrixRadio("");
                })();
              }}
              disabled={
                tasksUpdating ||
                (riskMatrixDrift && syncPlanToRiskMatrixRadio === "")
              }
            >
              Simulate
            </button>
          </div>
        </div>
      </LoadingWrapper>
    </Modal>
  );
};
