import React, { useState } from "react";

import { firebaseFunction } from "helpers";

import { Button, Input } from "@ehabitation/ui";
import { useIsMounted } from "hooks";
import { Spinner } from "Components/Spinner";
import { logSignupEvent } from "helpers/analytics";
import ReCAPTCHA from "react-google-recaptcha";
import config from "config";

export const register = async (
  email: string,
  firstName: string,
  lastName: string,
  company: string,
  companyRole: string,
  industry: string,
  phone: string,
  whereFrom: string,
  password: string
) => {
  const registerUser = firebaseFunction("registerUser");
  await registerUser({
    email,
    firstName,
    lastName,
    company,
    companyRole,
    industry,
    phone,
    whereFrom,
    password,
  });
};

const OTHER = "Other";

const companyRoles = [
  "Site Manager or Operative",
  "Planner / Senior Planner",
  "Principal Planner",
  "Project Director",
  "Head of Planning",
  "Head of Insurance",
  "Insurance Manager",
  OTHER,
];

const industries = ["Construction", OTHER];

const useRegister = () => {
  const isMounted = useIsMounted();

  const [isLoading, setIsLoading] = useState(false);
  const [isRecaptchaValid, setRecaptchaValid] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [companyRole, setCompanyRole] = useState("");
  const [otherRole, setOtherRole] = useState("");
  const [industry, setIndustry] = useState("Construction");
  const [otherIndustry, setOtherIndustry] = useState("");
  const [phone, setPhone] = useState("");
  const [whereFrom, setWhereFrom] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setSuccess("");
    setError("");
    if (e.target.name === "email") setEmail(e.target.value);
    if (e.target.name === "firstName") setFirstName(e.target.value);
    if (e.target.name === "lastName") setLastName(e.target.value);
    if (e.target.name === "company") setCompany(e.target.value);
    if (e.target.name === "companyRole") setCompanyRole(e.target.value);
    if (e.target.name === "otherRole") setOtherRole(e.target.value);
    if (e.target.name === "industry") setIndustry(e.target.value);
    if (e.target.name === "otherIndustry") setOtherIndustry(e.target.value);
    if (e.target.name === "phone") setPhone(e.target.value);
    if (e.target.name === "whereFrom") setWhereFrom(e.target.value);
    if (e.target.name === "password") setPassword(e.target.value);
    if (e.target.name === "confirmPassword") setConfirmPassword(e.target.value);
  };

  const isValid = () => {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;
    const commonSequences = ["1234", "abcd", "password", "qwerty"];
    if (
      !email?.trim() ||
      !firstName?.trim() ||
      !lastName?.trim() ||
      !company?.trim() ||
      !companyRole?.trim() ||
      !industry?.trim() ||
      !phone?.trim() ||
      !whereFrom?.trim() ||
      !password?.trim() ||
      !confirmPassword?.trim()
    )
      return false;
    if (companyRole === OTHER && !otherRole?.trim()) return false;
    if (industry === OTHER && !otherIndustry?.trim()) return false;
    if (password !== confirmPassword) return false;
    if (!passwordRegex.test(password)) return false;
    if (commonSequences.some(seq => password.includes(seq))) return false;
    return true;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSuccess("");

    if (!isValid()) return setError("Please complete all fields and ensure passwords match and meet the requirements");

    setIsLoading(true);
    setError("");
    try {
      await register(
        email,
        firstName,
        lastName,
        company,
        companyRole === OTHER ? otherRole : companyRole,
        industry === OTHER ? otherIndustry : industry,
        phone,
        whereFrom,
        password
      );
      logSignupEvent();
      if (isMounted()) {
        setFirstName("");
        setLastName("");
        setEmail("");
        setCompany("");
        setCompanyRole("");
        setOtherRole("");
        setIndustry("");
        setOtherIndustry("");
        setPhone("");
        setWhereFrom("");
        setPassword("");
        setConfirmPassword("");
        setSuccess(
          `Account created, check the "${email}" inbox to set your password & login.`
        );
      }
    } catch (error) {
      if (isMounted()) {
        console.error(error);
        setIsLoading(false);
        setError(
          "Account creation failed, please try again or contact support@ehab.co if the error persists."
        );
      }
    }
  };

  return {
    email,
    firstName,
    lastName,
    company,
    companyRole,
    otherRole,
    industry,
    otherIndustry,
    phone,
    whereFrom,
    password,
    confirmPassword,
    isLoading,
    success,
    error,
    isRecaptchaValid,
    handleChange,
    handleSubmit,
    setRecaptchaValid,
  };
};

const RegisterForm = () => {
  const {
    email,
    firstName,
    lastName,
    company,
    companyRole,
    otherRole,
    industry,
    otherIndustry,
    phone,
    whereFrom,
    password,
    confirmPassword,
    isLoading,
    success,
    error,
    isRecaptchaValid,
    handleChange,
    handleSubmit,
    setRecaptchaValid,
  } = useRegister();

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col items-stretch w-[min(68rem,100vw)] px-4 gap-8"
      aria-label="Create Account"
    >
      {success ? (
        <p className="font-bold pt-4 text-emerald-600 text-center">{success}</p>
      ) : (
        <>
          <div className="flex flex-wrap gap-8">
            <div className="flex-grow flex flex-col items-stretch gap-2">
              <label
                className="font-bold text-2xl self-start pl-8"
                htmlFor="firstName"
              >
                First Name
              </label>
              <Input
                className="min-w-[24rem]"
                value={firstName}
                onChange={handleChange}
                type="text"
                name="firstName"
                aria-label="First Name"
                disabled={isLoading}
              />
            </div>
            <div className="flex-grow flex flex-col items-stretch gap-2">
              <label
                className="font-bold text-2xl self-start pl-8"
                htmlFor="lastName"
              >
                Last Name
              </label>
              <Input
                className="min-w-[24rem]"
                value={lastName}
                onChange={handleChange}
                type="text"
                name="lastName"
                aria-label="Last Name"
                disabled={isLoading}
              />
            </div>
          </div>
          <div className="flex flex-wrap gap-8">
            <div className="flex-grow flex flex-col items-stretch gap-2">
              <label
                className="font-bold text-2xl self-start pl-8"
                htmlFor="email"
              >
                Email
              </label>
              <Input
                className="min-w-[24rem]"
                value={email}
                onChange={handleChange}
                type="email"
                autoComplete="email"
                name="email"
                aria-label="Email"
                disabled={isLoading}
              />
            </div>
            <div className="flex-grow flex flex-col items-stretch gap-2">
              <label
                className="font-bold text-2xl self-start pl-8"
                htmlFor="company"
              >
                Company
              </label>
              <Input
                className="min-w-[24rem]"
                value={company}
                onChange={handleChange}
                type="text"
                name="company"
                aria-label="Company"
                disabled={isLoading}
              />
            </div>
          </div>

          <div className="flex flex-wrap gap-8">
            <div className="flex-grow flex flex-col items-stretch gap-2">
              <label
                className="font-bold text-2xl self-start pl-8"
                htmlFor="companyRole"
              >
                Role
              </label>
              <select
                className="text-[1.6rem] sm:text-[1.4rem] min-w-[24rem] outline-none focus:shadow-[0_0_0.2rem_0.1rem_#13cd89] bg-[url('/src/assets/icons/chevron.svg')] bg-[right_-20px_top_50%]
                bg-no-repeat bg-[9rem_auto,100%] rounded-full border border-[#444444] border-solid
                py-4 pl-12 pr-14 appearance-none"
                name="companyRole"
                id="companyRole"
                value={companyRole}
                onChange={handleChange}
                disabled={isLoading}
              >
                <option value="">---</option>
                {companyRoles.map((role) => (
                  <option key={role} value={role}>
                    {role}
                  </option>
                ))}
              </select>
              {companyRole === OTHER && (
                <Input
                  className="min-w-[24rem]"
                  value={otherRole}
                  onChange={handleChange}
                  type="text"
                  placeholder="Other Role"
                  name="otherRole"
                  aria-label="Other Role"
                  disabled={isLoading}
                />
              )}
            </div>
            <div className="flex-grow flex flex-col items-stretch gap-2">
              <label className=" font-bold text-2xl pl-8" htmlFor="industry">
                Industry
              </label>
              <select
                className="text-[1.6rem] sm:text-[1.4rem] min-w-[24rem] outline-none focus:shadow-[0_0_0.2rem_0.1rem_#13cd89] bg-[url('/src/assets/icons/chevron.svg')] bg-[right_-20px_top_50%]
                bg-no-repeat bg-[9rem_auto,100%] rounded-full border border-[#444444] border-solid
                py-4 pl-12 pr-14 appearance-none"
                name="industry"
                id="industry"
                value={industry}
                onChange={handleChange}
                disabled={isLoading}
              >
                <option value="">---</option>
                {industries.map((industry) => (
                  <option key={industry} value={industry}>
                    {industry}
                  </option>
                ))}
              </select>
              {industry === OTHER && (
                <Input
                  className="self-stretch min-w-[24rem]"
                  value={otherIndustry}
                  onChange={handleChange}
                  type="text"
                  placeholder="Other Industry"
                  name="otherIndustry"
                  aria-label="Other Industry"
                  disabled={isLoading}
                />
              )}
            </div>
          </div>

          <div className="flex flex-wrap gap-8">
            <div className="flex-grow flex flex-col items-stretch gap-2">
              <label
                className="font-bold text-2xl self-start pl-8"
                htmlFor="phone"
              >
                Phone number
              </label>
              <Input
                className="min-w-[26rem]"
                value={phone}
                onChange={handleChange}
                type="text"
                name="phone"
                aria-label="Phone"
                disabled={isLoading}
              />
            </div>
            <div className="flex-grow flex flex-col items-stretch gap-2">
              <label
                className="font-bold text-2xl self-start pl-8"
                htmlFor="whereFrom"
              >
                Where did you hear about EHAB?
              </label>
              <Input
                className="min-w-[24rem]"
                value={whereFrom}
                onChange={handleChange}
                type="text"
                name="whereFrom"
                aria-label="Where did you hear about EHAB?"
                disabled={isLoading}
              />
            </div>
          </div>

          <div className="flex flex-wrap gap-8">
            <div className="flex-grow flex flex-col items-stretch gap-2">
              <label
                className="font-bold text-2xl self-start pl-8"
                htmlFor="password"
              >
                Password
              </label>
              <Input
                className="min-w-[24rem]"
                value={password}
                onChange={handleChange}
                type="password"
                name="password"
                aria-label="Password"
                disabled={isLoading}
              />
              <p className="text-s text-red-600 pl-8">
                Password must be at least 8 characters long, contain a number, an uppercase letter, a lowercase letter, a special character, and avoid common sequences.
              </p>
            </div>
            <div className="flex-grow flex flex-col items-stretch gap-2">
              <label
                className="font-bold text-2xl self-start pl-8"
                htmlFor="confirmPassword"
              >
                Confirm Password
              </label>
              <Input
                className="min-w-[24rem]"
                value={confirmPassword}
                onChange={handleChange}
                type="password"
                name="confirmPassword"
                aria-label="Confirm Password"
                disabled={isLoading}
              />
            </div>
          </div>

          <div className="flex justify-center items-center">
            {error && <p className="pt-4 text-red-700 text-center">{error}</p>}

            {isLoading && (
              <div className="py-4">
                <Spinner />
              </div>
            )}

            {
              <ReCAPTCHA
                className="self-center"
                sitekey={config.REGISTRATION_RECAPTCHA_KEY}
                onChange={(value) => {
                  setRecaptchaValid(!!value);
                }}
              />
            }

            <Button
              className="mb-4 mt-6 self-center"
              type="submit"
              disabled={isLoading || !isRecaptchaValid}
            >
              Submit
            </Button>
          </div>
        </>
      )}
    </form>
  );
};

export default RegisterForm;