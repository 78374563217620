import { httpsCallable, HttpsCallableOptions } from "firebase/functions";
import { functions } from "firebaseConfig";

type FirebaseFunctions =
  | "deleteSite"
  | "resetUserPassword"
  | "registerUser"
  | "deleteDivision"
  | "createNewDivision"
  | "updateDivision"
  | "masterGrantRole"
  | "GenerateRiskCalendars"
  | "GenerateHistoricData"
  | "GenerateTaskExport"
  | "GenerateSafranDowntime"
  | "GenerateAcumenRiskPrePostCalendar"
  | "GenerateP6RiskCalendar"
  | "GeneratePRARiskCalendar"
  | "GenerateOpcRiskCalendar"
  | "RunGenerateAdjustedCalendar"
  | "createNewProject"
  | "updateProject"
  | "deleteProject"
  | "createNewSite"
  | "updateSite"
  | "deleteSite"
  | "createNewUser"
  | "updateUser"
  | "deleteUser"
  | "createWeatherBaselineReport"
  | "GenerateBaselinePDF"
  | "GenerateSiteWeatherCalendar"
  | "ImportRiskMatrix"
  | "ExportRiskMatrix"
  | "PRAPlnxMapping"
  | "CopyPlan"
  | "CopyRiskMatrix"
  | "SafranProjectMapping"
  | "SafranRiskMapping"
  | "GenerateHistoricStats"
  | "GenerateSimulationHeatmap"
  | "GenerateSiteHeatmap"
  | "SanitizeTasks";

export const firebaseFunction = <T>(functionName: FirebaseFunctions, options?: HttpsCallableOptions) =>
  httpsCallable<any, T>(functions, functionName, options);
