import { MonochromeButton, Spinner } from "@ehabitation/ui";
import { useExport } from "Components/RiskAnalysisExports/useExport";
import { usePlnxMapping } from "Components/RiskAnalysisExports/usePlnxMapping";
import { FC, useEffect, useRef, useState } from "react";
import { RiShareBoxFill } from "react-icons/ri";
import { useSafranMapping } from "../hooks";
import { StepDescription } from "./StepDescription";
import { BiDownload } from "react-icons/bi";
import { useOpcMapping } from "Components/RiskAnalysisExports/useOpcMapping";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

export const TraSteps: FC<{
  selectedFormat: string;
  orgId: string;
  siteId: string;
  planId: string;
  addSubscription: Function;
}> = ({ selectedFormat, orgId, siteId, planId, addSubscription }) => {
  const [associatedCalendars, setAssociatedCalendars] =
    useState<string>("associated");

  const {
    submitForm,
    downloadAcumenFile,
    acumenFilenamePre,
    acumenFilenamePost,
    previousAcumenFileAvailablePre,
    previousAcumenFileAvailablePost,
    setSelectedFormat,
    isExporting,
    handleAcumenRiskRegisterFile,
    existingAcumenRiskRegister,
    uploadedExistingRiskRegisterFilename,
  } = useExport(orgId, siteId, planId, associatedCalendars == "associated");
  useEffect(() => {
    setSelectedFormat(selectedFormat);
  }, [selectedFormat]);
  const plnxUploadRef = useRef<HTMLInputElement>(null);
  const {
    isMapping: isPLNXMapping,
    mappingDisabled,
    plnxFileName,
    plnxFileIsSet,
    handlePlnxFile,
    submitPlnx,
  } = usePlnxMapping(planId, orgId!);

  const {
    downloadSafranDowntime,
    exportingSafran: isExportingSafran,

    isMappingProject,
    handleProjectFile,
    submitProjectFile,
    projectFileSet: safranProjectFileIsSet,

    isMappingRisk,
    handleRiskFile,
    submitRiskFile,
    riskFileSet: safranRiskFileIsSet,
  } = useSafranMapping(planId, orgId!);

  const { opcPostFilePath, opcPreFilePath, downloadOpcFile } = useOpcMapping(
    planId,
    siteId
  );

  const acumenRiskRegisterUploadRef = useRef<HTMLInputElement>(null);
  const safranProjectUploadRef = useRef<HTMLInputElement>(null);
  const safranRiskUploadRef = useRef<HTMLInputElement>(null);

  return (
    <>
      {selectedFormat === "pra" && (
        <>
          <div className="flex justify-between items-center pb-14">
            <h2>Primavera Risk Analysis (PRA)</h2>
          </div>
          <div className="flex justify-between pb-10 items-center">
            <div className="flex">
              <span className="mr-10">Step 1</span>
              <div className="flex">
                <MonochromeButton
                  level="secondary"
                  disabled={isExporting}
                  onClick={submitForm}
                  className="ml-auto mr-4"
                >
                  Download PRA Weather Calendars .XLSX
                </MonochromeButton>
                {isExporting && <Spinner />}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between pb-10">
            <div className="flex">
              <div className="mr-10  whitespace-nowrap">Step 2</div>
              <div>Manually import weather calendars in PRA weather module</div>
            </div>
          </div>
          <div className="flex items-center justify-between pb-10">
            <div className="flex">
              <div className="mr-10  whitespace-nowrap">Step 3</div>
              <div>In PRA, save the plan and export .PLNX</div>
            </div>
          </div>
          <div className="flex items-center justify-between pb-10">
            <div className="flex">
              <span className="mr-10">Step 4</span>
              <div>
                <MonochromeButton
                  level="secondary"
                  onClick={() => plnxUploadRef.current?.click()}
                  disabled={isPLNXMapping}
                >
                  {plnxFileIsSet ? "Upload a new .PLNX" : "Upload .PLNX"}
                </MonochromeButton>
                <input
                  className="hidden"
                  type="file"
                  onChange={(e) => handlePlnxFile(e)}
                  accept=".plnx"
                  ref={plnxUploadRef}
                />
                <div>to assign weather calendars to activities</div>
                <div className="mt-2">
                  <span className="mr-2">File:</span>
                  <span className="font-bold">
                    {plnxFileIsSet ? plnxFileName : "None selected"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between pb-10 items-center">
            <div className="flex">
              <span className="mr-10">Step 5</span>
              <div className="flex">
                <MonochromeButton
                  className="mr-4"
                  level="secondary"
                  onClick={() => {
                    submitPlnx();
                    plnxUploadRef.current!.value = "";
                  }}
                  disabled={mappingDisabled && !plnxFileIsSet}
                >
                  Download .PLNX with calendars assigned.
                </MonochromeButton>
                {isPLNXMapping && <Spinner />}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between pb-10">
            <div className="flex">
              <div className="mr-10  whitespace-nowrap">Step 6</div>
              <div>Import the EHAB downloaded .PLNX file back into PRA.</div>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex">
              <div className="mr-10 whitespace-nowrap">Step 7</div>
              <div>
                Open the weather module and enable weather modeling to the plan.
                Close and re-open the weather module to see all imported
                calendars.
              </div>
            </div>
          </div>
        </>
      )}
      {selectedFormat === "acumen" && (
        <>
          <div className="flex justify-between items-center pb-14">
            <h2>Deltek Acumen Risk</h2>
            <div className="flex items-center">
              <MonochromeButton
                level="secondary"
                onClick={() =>
                  window.open(
                    "https://ehab.co/docs/how-to-integrate-acumen-risk-with-ehab/",
                    "_blank",
                    "noreferrer"
                  )
                }
              >
                Tutorial <RiShareBoxFill className="ml-2" />
              </MonochromeButton>
            </div>
          </div>
          <StepDescription
            step={1}
            description="(Optional) To append to an existing risk register, in Acumen, navigate to the risk tab, display the risk register and export it."
          />
          <StepDescription step={2}>
            <>
              <MonochromeButton
                level="secondary"
                onClick={() => acumenRiskRegisterUploadRef.current?.click()}
              >
                (Optional) Upload Acumen Risk Register.
              </MonochromeButton>
              <input
                className="hidden"
                type="file"
                onChange={async (e) => await handleAcumenRiskRegisterFile(e)}
                accept=".xlsx"
                ref={acumenRiskRegisterUploadRef}
              />
              <div className="mt-1 ml-2">
                <span className="mr-2">File:</span>
                <span className="font-bold">
                  {uploadedExistingRiskRegisterFilename
                    ? acumenRiskRegisterUploadRef.current?.files![0].name
                    : "None selected"}
                </span>
              </div>
            </>
          </StepDescription>
          <div className="flex justify-between pb-8 items-center">
            <div className="flex">
              <span className="mr-10">Step 3</span>
              <div className="flex">
                <MonochromeButton
                  level="secondary"
                  disabled={
                    isExporting ||
                    (!!existingAcumenRiskRegister &&
                      !uploadedExistingRiskRegisterFilename)
                  }
                  onClick={submitForm}
                  className="ml-auto mr-4"
                >
                  Generate Acumen Risk Calendars
                </MonochromeButton>
                {(isExporting ||
                  acumenFilenamePre == "pending" ||
                  acumenFilenamePost == "pending") && <Spinner />}
              </div>
            </div>
          </div>
          <div className="flex justify-between pb-8 items-center">
            <div className="flex">
              <span className="mr-10">Step 4</span>
              <div className="flex">
                <MonochromeButton
                  level="secondary"
                  disabled={isExporting || !previousAcumenFileAvailablePre}
                  onClick={() => {
                    if (acumenFilenamePre) {
                      downloadAcumenFile(acumenFilenamePre);
                    }
                  }}
                  className="ml-auto mr-4"
                >
                  <BiDownload className="mr-4 " />
                  Pre-mitigation calendar
                </MonochromeButton>
                <MonochromeButton
                  level="secondary"
                  disabled={isExporting || !previousAcumenFileAvailablePost}
                  onClick={() => {
                    if (acumenFilenamePost) {
                      downloadAcumenFile(acumenFilenamePost);
                    }
                  }}
                  className="ml-auto mr-4"
                >
                  <BiDownload className="mr-4 " />
                  Post-mitigation calendar
                </MonochromeButton>
                {(isExporting ||
                  acumenFilenamePre == "pending" ||
                  acumenFilenamePost == "pending") && <Spinner />}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between pb-8">
            <div className="flex">
              <div className="mr-10 whitespace-nowrap">Step 5</div>
              <div>Import the Risk Register spreadsheet into Acumen Risk.</div>
            </div>
          </div>
        </>
      )}
      {selectedFormat === "safran" && (
        <>
          <div className="flex justify-between items-center pb-14">
            <h2>Safran Risk</h2>
            <div className="flex items-center">
              <MonochromeButton
                level="secondary"
                onClick={() =>
                  window.open(
                    "https://ehab.co/docs/how-to-integrate-safran-risk-with-ehab/",
                    "_blank",
                    "noreferrer"
                  )
                }
              >
                Tutorial <RiShareBoxFill className="ml-2" />
              </MonochromeButton>
            </div>
          </div>
          <StepDescription step={1}>
            <>
              <MonochromeButton
                level="secondary"
                disabled={isExportingSafran}
                onClick={async () =>
                  addSubscription(await downloadSafranDowntime(planId, siteId))
                }
                className="ml-auto mr-4"
              >
                Export EHAB Downtime Samples
              </MonochromeButton>
              {isExportingSafran && <Spinner />}
            </>
          </StepDescription>
          <StepDescription
            step={2}
            description="In Safran Risk, 'Risk Calendars' tab, import EHAB's downtime samples xml."
          />
          <StepDescription
            step={3}
            description="In the 'Project Risks' tab, export the current project risks"
          />
          <StepDescription step={4}>
            <>
              <MonochromeButton
                level="secondary"
                onClick={() => safranProjectUploadRef.current?.click()}
              >
                {safranProjectFileIsSet
                  ? "Upload a new Project Risk file .XLSX"
                  : "Upload Project Risk file .XLSX"}
              </MonochromeButton>
              <input
                className="hidden"
                type="file"
                onChange={(e) => handleProjectFile(e)}
                accept=".xlsx"
                ref={safranProjectUploadRef}
              />
              <div className="mt-1 ml-2">
                <span className="mr-2">File:</span>
                <span className="font-bold">
                  {safranProjectFileIsSet &&
                  safranProjectUploadRef.current?.files![0]
                    ? safranProjectUploadRef.current?.files![0].name
                    : "None selected"}
                </span>
              </div>
            </>
          </StepDescription>
          <StepDescription step={5}>
            <div className="flex">
              <MonochromeButton
                className="mr-4"
                level="secondary"
                onClick={() => {
                  submitProjectFile();
                  safranProjectUploadRef.current!.value = "";
                }}
                disabled={isMappingProject || !safranProjectFileIsSet}
              >
                Download mapped Project Risk .XLSX
              </MonochromeButton>
              {isMappingProject && <Spinner />}
            </div>
          </StepDescription>
          <StepDescription
            step={6}
            description="In the `Project Risks` tab, upload the new mapped Project Risk file"
          />
          <StepDescription
            step={7}
            description="In the `Risk Mapping` tab, export the current risk mapping"
          />
          <StepDescription step={8}>
            <>
              <MonochromeButton
                level="secondary"
                onClick={() => safranRiskUploadRef.current?.click()}
              >
                {safranRiskFileIsSet
                  ? "Upload a new Risk Mapping file .XLSX"
                  : "Upload Risk Mapping file .XLSX"}
              </MonochromeButton>
              <input
                className="hidden"
                type="file"
                onChange={(e) => handleRiskFile(e)}
                accept=".xlsx"
                ref={safranRiskUploadRef}
              />
              <div className="mt-1 ml-2">
                <span className="mr-2">File:</span>
                <span className="font-bold">
                  {safranRiskFileIsSet
                    ? safranRiskUploadRef.current?.files![0].name
                    : "None selected"}
                </span>
              </div>
            </>
          </StepDescription>
          <StepDescription step={9}>
            <>
              <div className="flex">
                <MonochromeButton
                  className="mr-4"
                  level="secondary"
                  onClick={() => submitRiskFile()}
                  disabled={isMappingRisk || !safranRiskFileIsSet}
                >
                  Download mapped Risk Mapping .XLSX
                </MonochromeButton>
                {isMappingRisk && <Spinner />}
              </div>
            </>
          </StepDescription>
        </>
      )}
      {selectedFormat === "adjustedCalendars" && (
        <>
          <div className="flex justify-between items-center pb-14">
            <h2>Oracle Primavera P6</h2>
            <div />
          </div>
          <div className="flex justify-between pb-6 items-center">
            <div className="flex pb-4">
              <RadioGroup
                row
                aria-label="calendar-options"
                name="calendar-options"
                value={associatedCalendars}
                onChange={(event) => {
                  setAssociatedCalendars(event.target.value);
                }}
              >
                <FormControlLabel
                  value="associated"
                  control={<Radio />}
                  label="Replace existing calendars"
                />
                A copy of your project, with new activity calendars containing
                the calculated weather impact. Previous calendars are still kept
                in the project, but are not assigned to activities. 
                <FormControlLabel
                  value="new"
                  control={<Radio />}
                  label="Empty project with new calendars"
                  className="flex pt-6"
                />
                A project with no tasks, containing just calendars containing
                the calculated weather impact. Each calendar will be named after
                the activity type it represents. Import and manually assign them
                to activities of your choice.
              </RadioGroup>
            </div>
          </div>
          <div className="flex justify-between pt-4 pb-10 items-center">
            <div className="flex">
              <span className="mr-10">Step 1</span>
              <div className="flex">
                <MonochromeButton
                  level="secondary"
                  disabled={isExporting}
                  onClick={submitForm}
                  className="ml-auto mr-4"
                >
                  Export Project
                </MonochromeButton>
                {isExporting && <Spinner />}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between pb-10">
            <div className="flex">
              <div className="mr-10 whitespace-nowrap">Step 2</div>
              <div>Import the project into P6.</div>
            </div>
          </div>

          <div className="flex flex-col items-left pb-10">
            <b>If you chose to Replace existing calendars</b>
          </div>
          <div className="flex items-center justify-between pb-10">
            <div className="flex">
              <div className="mr-10 whitespace-nowrap">Step 3</div>
              <div>
                <b>Update Project settings: </b>
                While importing the file, if you see the Update Project Options
                dialogue, click{" "}
                <b>
                  <i>"Edit"</i>
                </b>
                . Then click on the{" "}
                <b>
                  <i>"Project"</i>
                </b>{" "}
                tab. Select{" "}
                <b>
                  <i>"Update Existing"</i>
                </b>{" "}
                for both <b>"Project Calendars"</b> and
                <b> "Schedule Options"</b>.
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between pb-10">
            <div className="flex">
              <div className="mr-10 whitespace-nowrap">Step 4</div>
              <div>
                <b>Check an activity calendar</b> that is expected to have
                weather impact. You should see that it now has an EHAB calendar
                associated with it. You can also check the{" "}
                <i>Enterprise {">"} Project</i> calendars to view the new EHAB
                calendars and the associated days off.
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between pb-10">
            <div className="flex">
              <div className="mr-10 whitespace-nowrap">Step 5</div>
              <div>
                <b>Re-schedule</b>: Click on{" "}
                <i>
                  <b>Schedule...</b>
                </i>
                . Make sure the option{" "}
                <i>"All Projects use their own data date"</i> is selected.
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between pb-10">
            <div className="flex">
              <div className="mr-10 whitespace-nowrap">Done!</div>
              <div>
                <b>
                  You should see now that activities have been extended to
                  accomodate for the weather risk estimated by EHAB.
                </b>
              </div>
            </div>
          </div>
        </>
      )}
      {selectedFormat === "oraclePrimavera" && (
        <>
          <div className="flex justify-between items-center pb-14">
            <h2>Oracle Primavera Cloud</h2>
          </div>
          <div className="flex justify-between pb-8 items-center">
            <div className="flex">
              <span className="mr-10">Step 1</span>
              <div className="flex">
                <MonochromeButton
                  level="secondary"
                  disabled={isExporting}
                  onClick={submitForm}
                  className="ml-auto mr-4"
                >
                  Generate Oracle Primavera Cloud Calendars
                </MonochromeButton>
                {(isExporting ||
                  acumenFilenamePre == "pending" ||
                  acumenFilenamePost == "pending") && <Spinner />}
              </div>
            </div>
          </div>
          <div className="flex justify-between pb-8 items-center">
            <div className="flex">
              <span className="mr-10">Step 1</span>
              <div className="flex">
                <MonochromeButton
                  level="secondary"
                  disabled={isExporting || !opcPreFilePath}
                  onClick={() =>
                    downloadOpcFile(opcPreFilePath!, "PreMitigation")
                  }
                  className="ml-auto mr-4"
                >
                  <BiDownload className="mr-4 " />
                  Pre Mitigation
                </MonochromeButton>
                {isExporting && <Spinner />}
              </div>
              <div className="flex">
                <MonochromeButton
                  level="secondary"
                  disabled={isExporting || !opcPostFilePath}
                  onClick={() =>
                    downloadOpcFile(opcPostFilePath!, "PostMitigation")
                  }
                  className="ml-auto mr-4"
                >
                  <BiDownload className="mr-4 " />
                  Post Mitigation
                </MonochromeButton>
                {isExporting && <Spinner />}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between pb-8">
            <div className="flex">
              <div className="mr-10 whitespace-nowrap">Step 2</div>
              <div>
                Import the Risk Register spreadsheet into Oracle Primavera.
              </div>
            </div>
          </div>
        </>
      )}
      {selectedFormat === "nodesLinks" && (
        <>
          <div className="flex justify-between items-center pb-14">
            <h2>Nodes and Links</h2>
            <div className="flex items-center">


              {/* <MonochromeButton


              <MonochromeButton
                level="secondary"
                onClick={() =>
                  window.open(
                    "https://www.youtube.com/watch?v=GDrLAwDXDYA",
                    "_blank",
                    "noreferrer"
                  )
                }
              >
                Tutorial <RiShareBoxFill className="ml-2" />

              </MonochromeButton> */}

            </div>
          </div>
          <div className="flex justify-between pt-4 pb-10 items-center">
            <div className="flex">
              <span className="mr-10">Step 1</span>
              <div className="flex">
                <MonochromeButton
                  level="secondary"
                  disabled={isExporting}
                  onClick={submitForm}
                  className="ml-auto mr-4"
                >
                  Export Project
                </MonochromeButton>
                {isExporting && <Spinner />}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between pb-10">
            <div className="flex">
              <div className="mr-10 whitespace-nowrap">Step 2</div>
              <div>
                Import the project into P6. This step is necessary to
                re-schedule activities. Note step 4 for settings to be done
                during the import.
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between pb-10">
            <div className="flex">
              <div className="mr-10 whitespace-nowrap">Step 3</div>
              <div>
                <b>Update Project settings: </b>
                While importing the file, when you see the Update Project
                Options dialogue, click{" "}
                <b>
                  <i>"Edit"</i>
                </b>
                . Then click on the{" "}
                <b>
                  <i>"Project"</i>
                </b>{" "}
                tab. Select{" "}
                <b>
                  <i>"Update Existing"</i>
                </b>{" "}
                for both <b>"Project Calendars"</b> and
                <b> "Schedule Options"</b>.
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between pb-10">
            <div className="flex">
              <div className="mr-10 whitespace-nowrap">Step 4</div>
              <div>
                <b>Check an activity calendar</b> that is expected to have
                weather impact. You should see that it now has an EHAB calendar
                associated with it. You can also check the{" "}
                <i>Enterprise {">"} Project</i> calendars to view the new EHAB
                calendars and the associated days off.
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between pb-10">
            <div className="flex">
              <div className="mr-10 whitespace-nowrap">Step 5</div>
              <div>
                <b>Re-schedule</b>: Click on{" "}
                <i>
                  <b>Schedule...</b>
                </i>
                . Make sure the option{" "}
                <i>"All Projects use their own data date"</i> is selected.
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between pb-10">
            <div className="flex">
              <div className="mr-10 whitespace-nowrap">Step 6</div>
              <div>
                <b>Export .XER</b>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between pb-10">
            <div className="flex">
              <div className="mr-10 whitespace-nowrap">Step 7</div>
              <div>
                <b>
                  Import the re-scheduled .xer into Nodes and Links. 
                  <p>You should see now that activities have been extended to accomodate for
                  the weather risk estimated by EHAB. 
                  </p>
                  <p>
                  Use the comparison feature to see your old project vs the new weatherwise project.
                  </p>
                </b>
              </div>
            </div>
          </div>
        </>
      )}
      {selectedFormat === "asta" && (
        <>
          <div className="flex justify-between items-center pb-14">
            <h2>Asta Powerproject</h2>
            <div className="flex items-center">
              <MonochromeButton
                level="secondary"
                onClick={() =>
                  window.open(
                    "https://youtu.be/PzmW9tGpbvM",
                    "_blank",
                    "noreferrer"
                  )
                }
              >
                Tutorial <RiShareBoxFill className="ml-2" />
              </MonochromeButton>
            </div>
          </div>
          <div className="flex justify-between pb-6 items-center">
            <div className="flex pb-4">
              <RadioGroup
                row
                aria-label="calendar-options"
                name="calendar-options"
                value={associatedCalendars}
                onChange={(event) => {
                  setAssociatedCalendars(event.target.value);
                }}
              >
                <FormControlLabel
                  value="associated"
                  control={<Radio />}
                  label="Replace existing calendars"
                />
                A copy of your project, with new activity calendars containing
                the calculated weather impact. Previous calendars are still kept
                in the project, but are not assigned to activities.
                <FormControlLabel
                  value="new"
                  control={<Radio />}
                  label="Empty project with new calendars"
                  className="flex pt-6"
                />
                A project with no tasks, containing just calendars containing
                the calculated weather impact. Each calendar will be named after
                the activity type it represents. Import and manually assign them
                to activities of your choice.
              </RadioGroup>
            </div>
          </div>
          <div className="flex justify-between pt-4 pb-10 items-center">
            <div className="flex">
              <span className="mr-10">Step 1</span>
              <div className="flex">
                <MonochromeButton
                  level="secondary"
                  disabled={isExporting}
                  onClick={submitForm}
                  className="ml-auto mr-4"
                >
                  Export Project
                </MonochromeButton>
                {isExporting && <Spinner />}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between pb-10">
            <div className="flex">
              <div className="mr-10 whitespace-nowrap">Step 2</div>
              <div>Import the project into Asta Powerproject.</div>
            </div>
          </div>
          <div className="flex items-center justify-between pb-10">
            <div className="flex">
              <div className="mr-10 whitespace-nowrap">Done!</div>
              <div>
                <p>
                <b>Check that the calendars</b> have been imported, on the left bar.
                You can right click on a calendar, <i>"Properties"</i>, and flick between calendars to see the days off.
                </p>
                <p>
                If you chose to Replace existing calendars, select an activity, and in the bottom bar <i>"Tasks" { ">" } "Dates"</i>
                to see the associated EHAB calendar.
                </p>
                <p>
                Also, in the Gantt chart, notice the blue bars representing the weather days off that have been blocked.
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
