import { FC, memo, useMemo } from "react";
import {
  ITask,
  ITaskCategory,
  WeatherThresholds,
} from "@ehabitation/ts-utils/browser";
import NameCell from "./NameCell";
import StartCell from "./StartCell";
import ConfidenceCell from "./ConfidenceCell";
import EndCell from "./EndCell";
import { findTextColour } from "./helpers";
import { useSetCurrentTask } from "./useSetCurrentTask";
import { StyledRow } from "./styles";
import { useCompareThresholds } from "./useCompareThresholds";
import moment from "moment";

export interface IProps {
  id: string;
  task: ITask;
  taskAccuracy: number;
  taskBaseAccuracy: number;
  isWBS: boolean;
  taskCategoryMap: Record<string, ITaskCategory>;
  selected: boolean;
  hasSimulation: boolean;
}

const TaskListRow: FC<IProps> = ({
  id,
  task,
  taskAccuracy,
  taskBaseAccuracy,
  isWBS,
  taskCategoryMap,
  selected,
  hasSimulation,
}) => {
  const {
    isCriticalPath,
    title,
    start,
    end,
    milestone,
    taskType,
    levelOfEffort,
  } = task;
  const thresholdDiff = useCompareThresholds(task, taskType, taskCategoryMap);

  const { setCurrentTask } = useSetCurrentTask(id);

  const textColor = useMemo(() => {
    return findTextColour(taskAccuracy, taskBaseAccuracy);
  }, [taskAccuracy, taskBaseAccuracy]);

  return (
    <StyledRow
      key={id}
      data-testid={id}
      onClick={setCurrentTask}
      isWBS={isWBS}
      isSelected={selected}
      className="table-row"
      role="button"
    >
      {"\xA0".repeat(task.wbsHierarchyLevel!)}

      <NameCell
        isMilestone={!!task.milestone}
        isLevelOfEffort={!!task.levelOfEffort}
        title={title}
        wbsId={isWBS ? task.id : undefined}
      />
      <StartCell
        start={moment(start).format("DD-MM-YYYY")}
        milestone={milestone}
      />
      <EndCell
        end={moment(end!).format("DD-MM-YYYY")}
        color={textColor!}
        milestone={milestone}
      />
      {hasSimulation ? (
        <ConfidenceCell
          milestone={milestone}
          levelOfEffort={levelOfEffort}
          accuracy={taskAccuracy}
          userSelectedConfidence={taskBaseAccuracy !== taskAccuracy}
          color={textColor!}
          WBS={isWBS ? task : null}
          isInCriticalPath={!!isCriticalPath}
          thresholdDiff={thresholdDiff}
        />
      ) : (
        <div className="animate-pulse w-[5.2rem] text-xl grid place-content-center">
          -
        </div>
      )}
    </StyledRow>
  );
};

export default memo(TaskListRow);
