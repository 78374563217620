import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button, Input, PasswordInput } from "@ehabitation/ui";
import {
  EmailAuthProvider,
  MultiFactorError,
  MultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  getMultiFactorResolver,
  reauthenticateWithCredential,
  signInWithEmailAndPassword,
} from "firebase/auth";

import { auth } from "firebaseConfig";
import { useIsMounted } from "hooks";
import { logLoginEvent } from "helpers/analytics";

export const login = async (email: string, password: string) => {
  return signInWithEmailAndPassword(auth, email, password);
};

const useLogin = (reauthenticate: boolean) => {
  const isMounted = useIsMounted();
  const location = useLocation();

  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mfaCode, setMfaCode] = useState("");
  const [verificationId, setVerificationId] = useState("");
  const [mfChallenge, setMfChallenge] = useState(false);
  const [mfResolver, setMfResolver] = useState<MultiFactorResolver | null>();
  const [recaptchaVerifier, setRecaptchaVerifier] =
    useState<RecaptchaVerifier>();

  useEffect(() => {
    if (mfResolver && !recaptchaVerifier) {
      const verifier = new RecaptchaVerifier(auth, "mfa-captcha", {
        size: "invisible",
      });
      const mfInfo = mfResolver.hints.find(
        (f) => f.factorId === PhoneMultiFactorGenerator.FACTOR_ID
      );
      const phoneInfoOptions = {
        multiFactorHint: mfInfo,
        session: mfResolver.session,
      };

      const phoneAuthProvider = new PhoneAuthProvider(auth);
      phoneAuthProvider
        .verifyPhoneNumber(phoneInfoOptions, verifier!)
        .then(function (verificationId) {
          setVerificationId(verificationId);
        });
      setRecaptchaVerifier(verifier);
    }
  }, [mfResolver]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    if (e.target.name === "email") setEmail(e.target.value);
    if (e.target.name === "password") setPassword(e.target.value);
    if (e.target.name === "mfaCode") setMfaCode(e.target.value);
  };

  const isValid = () => {
    if (!email || !password) return false;
    return true;
  };

  const verifyCode = async () => {
    const cred = PhoneAuthProvider.credential(verificationId, mfaCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
    await mfResolver?.resolveSignIn(multiFactorAssertion);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    if (e.currentTarget.name === "mfa-code") verifyCode();
    else {
      handleLogin(e);
    }
  };

  const handleLogin = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    if (!isValid()) return setError("Please provide an email and password");

    setError("");
    setIsLoading(true);

    try {
      if (reauthenticate) {
        reauthenticateWithCredential(
          auth.currentUser!,
          EmailAuthProvider.credential(email, password)
        );
      } else {
        await login(email, password);
      }
      logLoginEvent();
      if (isMounted()) {
        location.pathname = location.state?.from || "/";
      }
    } catch (e) {
      const error = e as { code: string };
      if (isMounted()) {
        setIsLoading(false);
        if (error.code == "auth/multi-factor-auth-required") {
          // evt.stopPropagation();
          const resolver = getMultiFactorResolver(
            auth,
            error as MultiFactorError
          );

          setMfResolver(resolver);
          setMfChallenge(true);
        } else if (
          [
            "auth/user-not-found",
            "auth/wrong-password",
            "auth/invalid-email",
          ].includes(error?.code)
        ) {
          setError("Incorrect email or password.");
        } else {
          console.error(error);
          setError(
            "Login failed, please try again or contact support@ehab.co if the error persists."
          );
        }
      }
    }
  };

  return {
    email,
    password,
    error,
    isLoading,
    handleChange,
    handleSubmit,
    mfChallenge,
    verifyCode,
  };
};

const LoginForm = ({ reauthenticate }: { reauthenticate: boolean }) => {
  const {
    email,
    password,
    error,
    handleChange,
    handleSubmit,
    isLoading,
    mfChallenge,
    verifyCode,
  } = useLogin(reauthenticate);

  return (
    <>
      <div id="mfa-captcha"></div>

      {mfChallenge && (
        <>
          <Input
            className="mb-8 self-stretch"
            type="text"
            onChange={handleChange}
            placeholder="SMS Code"
            name="mfaCode"
            aria-label="SMS Code"
            disabled={isLoading}
          />

          <Button
            id="verify"
            className="my-4"
            type="button"
            tabIndex={1}
            disabled={isLoading}
            onClick={verifyCode}
          >
            Submit
          </Button>
        </>
      )}
      {!mfChallenge && (
        <form
          id="login-form"
          name={"login-form"}
          onSubmit={handleSubmit}
          className="flex flex-col items-center w-full max-w-xl"
          aria-label="Login"
        >
          <Input
            className="mb-8 self-stretch"
            value={email}
            onChange={handleChange}
            type="email"
            autoComplete="email"
            placeholder="Email"
            tabIndex={1}
            name="email"
            aria-label="Email"
            disabled={isLoading}
          />
          <PasswordInput
            className="self-stretch"
            value={password}
            autoComplete="current-password"
            onChange={handleChange}
            placeholder="Password"
            tabIndex={1}
            name="password"
            aria-label="Password"
            disabled={isLoading}
          />

          <Link to="/reset_password" className="text-blue-500 mt-2">
            Forgot your password?
          </Link>

          {error && <p className="pt-4 text-red-700 text-center">{error}</p>}

          <Button
            id="sign-in"
            className="my-4"
            type="submit"
            tabIndex={1}
            disabled={isLoading}
          >
            Submit
          </Button>
        </form>
      )}
    </>
  );
};

export default LoginForm;
