import styled, { css } from "styled-components";
import {
  flex,
  fontSize,
  spacing,
  colors,
  font,
  taskListWidth,
  scrollbarOff,
  headerHeight,
  planControlsHeight,
  planBottomRowHeight,
  grays,
} from "styles";

export const StyledTaskList = styled.div`
  height: calc(
    100vh - ${headerHeight} - ${planControlsHeight} - ${planBottomRowHeight} -
      ${spacing.l} - 18px
  );
  overflow-y: none;
  width: ${taskListWidth};
  min-width: ${taskListWidth};
  margin-right: ${spacing.l};

  .tasks {
    ${scrollbarOff()};
    height: 100%;
    width: 100%;
    overflow-y: scroll;

    .tasks-virtual {
      width: 100%;
      position: relative;
    }
  }
`;

export const ConfidenceCellWrapper = styled.div`
  display: flex;
  font-size: 1rem;
`;

interface IStyledRow {
  isWBS?: boolean;
  isHeader?: boolean;
  isSelected?: boolean;
}
const rowBGStyles = css<IStyledRow>`
  background-color: ${(props) =>
    props.isWBS ? grays.veryLight : props.isHeader ? "white" : colors.primary};
`;
export const StyledRow = styled.div<IStyledRow>`
  ${flex("row", "flex-start", "center")};
  cursor: pointer;
  font-weight: ${(props) => (props.isWBS || props.isHeader ? 700 : 300)};
  width: 100%;
  box-sizing: content-box;
  height: 3.8rem;
  ${(props) => (props.isSelected ? rowBGStyles : "")};

  &:hover {
    ${rowBGStyles};

    .green,
    .red,
    .yellow {
      color: ${colors.ganttText};
      font-weight: 300;
    }
  }

  .milestone {
    color: ${colors.blue};
    font-weight: 600;
  }

  .cell-date,
  .cell-value {
    ${flex("row", "flex-start", "center")}
    font-size: ${fontSize.xs};
  }

  .title {
    ${font("title")};
    text-transform: capitalize;
    font-size: ${fontSize.xs};
  }

  .cell-value {
    width: 7.2rem;
  }

  .cell-value.confidence {
    width: 5.2rem;
  }

  .cell-show-children {
    ${flex("row", "flex-start", "flex-start")};

    > span {
      padding-left: ${spacing.s};
    }
  }

  .cell-name {
    flex-grow: 1;
    white-space: nowrap;
    padding-left: ${spacing.s};
  }

  .cell-date {
    min-width: 7.7rem;
  }

  .cell-value {
    text-align: center;
    align-self: center;
    flex-shrink: 0;
  }

  .green,
  .yellow,
  .red {
    font-weight: ${(props) => (props.isSelected ? 300 : 700)};
  }

  .title {
    ${font("title")};
    text-transform: capitalize;
  }

  .milestone {
    color: ${colors.blue};
    font-weight: 600;
  }

  .green {
    color: ${(props) =>
      props.isSelected ? colors.ganttText : colors.ganttGreen};
  }

  .yellow {
    color: ${(props) =>
      props.isSelected ? colors.ganttText : colors.ganttYellow};
  }

  .red {
    color: ${(props) =>
      props.isSelected ? colors.ganttText : colors.ganttRed};
  }

  .black {
    color: ${colors.ganttText};
  }
`;

export const StyledAlertIcon = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  position: relative;
  margin-left: 0.5rem;

  > .icon {
    height: 100%;
    width: 100%;
    color: ${grays.medium};
  }

  .critical-path-alert {
    position: absolute;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    background: red;
    bottom: -0.1rem;
    right: 0;
  }
`;
