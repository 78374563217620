import { ISite } from "@ehabitation/ts-utils/browser";
import {
  Alert,
  Button,
  ButtonIsland,
  LoadingWrapper,
  Modal,
  Spinner,
} from "@ehabitation/ui";
import Loader from "Components/Loader/Loader";
import { logRiskMatrixModify, logRiskMatrixView } from "helpers/analytics";
import {
  usePlanRiskMatrix,
  useRiskMatrixTopLevelCategories,
  useSiteRiskMatrix,
} from "hooks";
import { FC, useEffect } from "react";
import { AiOutlineDownload, AiOutlineUpload } from "react-icons/ai";
import { RiDeleteBinLine, RiSaveLine } from "react-icons/ri";
import { TableRow } from "ui-shadcn/table";
import { RiskMatrixFileType } from "./helpers";
import {
  useImportExportRiskMatrix,
  useRiskMatrixChangeMap,
  useUserCanEditRiskMatrix
} from "./hooks";

import { useHasVisibility } from "hooks/useHasVisibility";
import { Table, TableBody, TableHead, TableHeader } from "ui-shadcn/table";
import ActivityCodeGroupInput from "./CategoryThresholdsRow/ActivityCodeGroupInput";
import CategoryThresholdsRowNew from "./CategoryThresholdsRow/CategoryThresholdsRowNew";
import { ImportRiskMatrixModal } from "./ImportRiskMatrixModal";

const CategoryThresholdsNew: FC<{
  site: ISite;
  selectedRiskMatrixType: string;
  planId?: string;
}> = ({ site, selectedRiskMatrixType, planId }) => {
  const { riskMatrix: siteRiskMatrix, isLoading: isLoadingSiteRiskMatrix } =
    useSiteRiskMatrix(site);
  const { riskMatrix: planRiskMatrix, isLoading: isLoadingPlanRiskMatrix } =
    usePlanRiskMatrix(planId);

  const riskMatrix =
    selectedRiskMatrixType == "plan" ? planRiskMatrix : siteRiskMatrix;

  const { hasVisibility } = useHasVisibility(site);

  useEffect(() => {
    riskMatrix?.id && logRiskMatrixView(site.id, riskMatrix?.id);
  }, [site.id, riskMatrix?.id]);

  const { categories, isLoading: isLoadingCategories } =
    useRiskMatrixTopLevelCategories(riskMatrix?.id);

  // Temporarely commented out as we're always displaying the project categories and threholds
  // const { activeCategories, isLoading: isLoadingActiveCategories } =
  //   usePlanActiveCategories(site.id, site.mainPlanId);

  const {
    importing,
    importModalOpen,
    importNewRiskMatrix,
    openImport,
    closeImport,
    exporting,
    exportRiskMatrix,
    errors,
  } = useImportExportRiskMatrix();

  const {
    changeMap,
    updateChangeMap,
    numChanged,
    isValid,
    isSaving,
    saveThresholds,
    saveDisabled,
    discardChanges,
    discardDisabled,
    activityCodeGroup,
    setActivityCodeGroup,
    newActivityCodeGroup,
    setNewActivityCodeGroup,
  } = useRiskMatrixChangeMap(riskMatrix);

  const userCanEdit = useUserCanEditRiskMatrix(site, riskMatrix);

  return riskMatrix && categories ? (
    <div className="relative flex-grow min-h-0 pl-10">
      <ul className="h-full overflow-y-scroll">
        <LoadingWrapper
          loading={
            isLoadingPlanRiskMatrix ||
            isLoadingSiteRiskMatrix ||
            isLoadingCategories
          }
        >
          <div className="w-1/2 pt-8 pb-8" data-testid="row-container">
            <div className="flex items-center">
              <h3 className="mr-4">Activity Code Auto-Matching</h3>
              <ActivityCodeGroupInput
                type="Activity Code Group"
                readOnly={false}
                className="items-center ml-6"
                activityCodeGroup={activityCodeGroup}
                label="Activity Code Group"
                handleChange={(value: string) => setNewActivityCodeGroup(value)}
              />
            </div>
            <div className="text-s mt-2">
              Specify the activity code from your plan that corresponds to each
              type of activity. For each EHAB category, indicate the matching
              activity code value so that EHAB can automatically pair them.
            </div>
          </div>

            <div className="" data-testid="row-container">
                <h3 className="mt-8 mb-8">
                  Risk Categories 
                </h3>
              <Table className="text-xl">
                {/* <TableCaption>A list of your recent invoices.</TableCaption> */}
                <TableHeader>
                  <TableRow>
                    <TableHead className="w-[40px]"></TableHead>
                    <TableHead>Category</TableHead>
                    <TableHead className="w-[100px]">Shift</TableHead>
                    <TableHead>Automatic mapping</TableHead>
                    <TableHead className="w-[300px]">Thresholds</TableHead>
                    <TableHead className="w-[300px]">
                      Probability of Impact
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {categories.map((category) => (
                    <CategoryThresholdsRowNew
                      key={category.id}
                      category={category}
                      updateChangeMap={updateChangeMap}
                      changeMap={changeMap}
                      readOnly={!userCanEdit || isSaving}
                      hasVisibility={hasVisibility}
                      site={site}
                    />
                  ))}
                </TableBody>
              </Table>
            </div>

          <ButtonIsland
            id="import-export"
            positionX="left"
            message="Manage Risk Matrix"
            buttons={[
              <Button
                key="import"
                className="mr-2"
                onClick={async () => {
                  if (!importing && !exporting) await openImport();
                }}
                disabled={!userCanEdit || isSaving || !!numChanged}
              >
                <AiOutlineDownload className="inline h-8 w-8" /> {"Import"}
              </Button>,
              <Button
                key="export"
                onClick={async () => {
                  if (!importing && !exporting) {
                    await exportRiskMatrix(
                      RiskMatrixFileType.XLSX,
                      riskMatrix?.id,
                      hasVisibility
                    );
                  }
                }}
                disabled={!userCanEdit || isSaving || !!numChanged}
              >
                <AiOutlineUpload className="inline h-8 w-8" /> {"Export"}
              </Button>,
            ]}
          />
          <ButtonIsland
            id="task-categories"
            message={
              numChanged
                ? isValid
                  ? `${numChanged} categor${
                      numChanged > 1 ? "ies" : "y"
                    } edited`
                  : "Some changes are invalid"
                : undefined
            }
            buttons={[
              <Button
                key="save"
                className="mr-2"
                type="submit"
                aria-label="Save"
                onClick={async () => {
                  if (!isSaving) {
                    await saveThresholds();
                    setActivityCodeGroup(newActivityCodeGroup);
                    logRiskMatrixModify(site.id, riskMatrix?.id);
                  }
                }}
                disabled={saveDisabled || !userCanEdit}
              >
                {isSaving ? (
                  <Spinner />
                ) : (
                  <>
                    <RiSaveLine className="inline" /> {"Save"}
                  </>
                )}
              </Button>,
              <Button
                key="discard"
                className="bg-red-500"
                type="submit"
                aria-label="Discard"
                onClick={() => {
                  if (!isSaving) discardChanges();
                }}
                disabled={discardDisabled || !userCanEdit}
              >
                {isSaving ? (
                  <Spinner />
                ) : (
                  <>
                    <RiDeleteBinLine className="inline" /> {"Discard"}
                  </>
                )}
              </Button>,
            ]}
          />
        </LoadingWrapper>
        {importModalOpen && (
          <ImportRiskMatrixModal
            completeFunction={async (file: File) =>
              await importNewRiskMatrix(riskMatrix, file)
            }
            loading={importing}
            exportDefaultTemplate={() =>
              exportRiskMatrix(RiskMatrixFileType.XLSX)
            }
            exporting={exporting}
            closeModal={closeImport}
            errors={errors}
          />
        )}
        {exporting && (
          <Modal id="riskmatrix-export">
            <Loader message={"Risk Matrix export is being processed..."} />
          </Modal>
        )}
      </ul>
    </div>
  ) : (
    <div className="w-full grid place-items-center h-72">
      <Spinner />
      <Alert
        alertText="No risk matrix found for project"
        alerting={
          !isLoadingPlanRiskMatrix &&
          !isLoadingSiteRiskMatrix &&
          !isLoadingCategories &&
          !riskMatrix
        }
      />
    </div>
  );
};

export default CategoryThresholdsNew;
