import { useEffect, useState } from "react";
import { db } from "firebaseConfig";
import {
  DocumentReference,
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import {
  CollectionType,
  IPlan,
  IRiskMatrix,
  ISite,
  ITaskCategory,
  transformRiskMatrixDoc,
} from "@ehabitation/ts-utils/browser";
import { useIsMounted } from "./useIsMounted";

export const getSiteRiskMatrixQuery = (site: ISite) => {
  let tier: CollectionType | undefined = undefined;
  let tierObjectId: string | undefined = undefined;

  if (site.project) {
    tier = CollectionType.Projects;
    tierObjectId = site.project;
  } else if (site.owner) {
    tier = CollectionType.Users;
    tierObjectId = site.owner;
  }

  return query(
    collection(db, "riskMatrix"),
    where("tier", "==", tier),
    where("tierObjectId", "==", tierObjectId)
  );
};

export const getPlanRiskMatrixQuery = (planId: string) => {
  return query(
    collection(db, "riskMatrix"),
    where("tier", "==", CollectionType.Plans),
    where("tierObjectId", "==", planId)
  );
};

export const useSiteRiskMatrix = (site?: ISite) => {
  const [isLoading, setIsLoading] = useState<boolean>(!!site);
  const isMounted = useIsMounted();

  const [riskMatrixRef, setRiskMatrixRef] = useState<DocumentReference>();
  const [riskMatrix, setRiskMatrix] = useState<IRiskMatrix>();

  useEffect(() => {
    if (site && !riskMatrixRef) {
      setIsLoading(true);
      const siteRiskMatrixQuery = getSiteRiskMatrixQuery(site);
      onSnapshot(siteRiskMatrixQuery, (snapshot) => {
        if (isMounted()) {
          if (snapshot.empty) {
            const riskMatrixRef = doc(db, "riskMatrix", "default");
            setRiskMatrixRef(riskMatrixRef);
          } else {
            const riskMatrixRef = snapshot.docs[0]?.ref;
            setRiskMatrixRef(riskMatrixRef);
          }
          setIsLoading(false);
        }
      });
    }
  }, [site]);

  useEffect(() => {
    if (riskMatrixRef) {
      const unsubscribe = onSnapshot(riskMatrixRef, (doc) => {
        if (isMounted()) {
          const riskMatrix = transformRiskMatrixDoc(doc.id, doc.data());
          setRiskMatrix(riskMatrix);
          setIsLoading(false);
        }
      });
      return unsubscribe;
    }
  }, [riskMatrixRef]);

  return { riskMatrix, isLoading };
};

export const usePlanRiskMatrix = (planId?: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(!!planId);
  const isMounted = useIsMounted();

  const [riskMatrixRef, setRiskMatrixRef] = useState<DocumentReference>();
  const [riskMatrix, setRiskMatrix] = useState<IRiskMatrix>();

  useEffect(() => {
    if (planId && !riskMatrixRef) {
      setIsLoading(true);
      const planRiskMatrixQuery = getPlanRiskMatrixQuery(planId);
      onSnapshot(planRiskMatrixQuery, (snapshot) => {
        if (isMounted()) {
          if (snapshot.empty) {
            setRiskMatrixRef(undefined);
            setRiskMatrix(undefined);
            setIsLoading(false);
          } else {
            const riskMatrixRef = snapshot.docs[0]?.ref;
            setRiskMatrixRef(riskMatrixRef);
          }
        }
      });
    }
  }, [planId]);

  useEffect(() => {
    if (riskMatrixRef) {
      const unsubscribe = onSnapshot(riskMatrixRef, (doc) => {
        if (isMounted()) {
          const riskMatrix = transformRiskMatrixDoc(doc.id, doc.data());
          setRiskMatrix(riskMatrix);
          setIsLoading(false);
        }
      });
      return unsubscribe;
    }
  }, [riskMatrixRef]);

  return { riskMatrix, isLoading };
};

export const useRiskMatrixTopLevelCategories = (riskMatrixId?: string) => {
  const [categories, setCategories] = useState<ITaskCategory[]>();
  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState<boolean>(!!riskMatrixId);

  useEffect(() => {
    if (riskMatrixId) {
      setIsLoading(true);
      const unsubscribe = subscribeToTopLevelRiskMatrix(
        riskMatrixId,
        (categories) => {
          if (isMounted()) {
            setCategories(categories);
            setIsLoading(false);
          }
        }
      );
      return unsubscribe;
    }
  }, [riskMatrixId]);

  return { categories, isLoading };
};

function subscribeToTopLevelRiskMatrix(
  riskMatrixId: string,
  updateData: (data: ITaskCategory[]) => void
) {
  const categoriesQuery = query(
    collection(
      db,
      CollectionType.RiskMatrix,
      riskMatrixId,
      CollectionType.Categories
    ),
    where("level", "in", ["category", "Category"])
  );

  return onSnapshot(categoriesQuery, (docs) => {
    const data: ITaskCategory[] = [];
    docs.forEach((doc) => {
      data.push({
        ...doc.data(),
        id: doc.id,
      } as ITaskCategory);
    });
    updateData(data);
  });
}
