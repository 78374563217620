import { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { ISite, isGuest as roleIsGuest } from "@ehabitation/ts-utils/browser";
import { StyledPlanControls } from "./styles";
import { PlanSelector } from "./PlanSelector";
import Tabs from "./Tabs/Tabs";
import { useSubscribeToAvailablePlans } from "./PlanSelector/useSubscribeToAvailablePlans";
import { setAsMainPlan } from "./thunks";
import { selectCurrentPlanId } from "store/project";
import { RiPlayLine } from "react-icons/ri";
import { Button } from "@ehabitation/ui";
import { selectTaskModified } from "store/ui";
import { selectLatestCurrentPlanSimulation } from "store/tasks";
import { selectRole } from "store/auth";
import moment from "moment";
import { SimulationModal } from "Pages/sites/components/SimulationModal";
import DiscardAlert from "./DiscardAlert";
import { discardPlan } from "helpers/firebase/plans";

const PlanControls: FC<{
  promptIsOpen: boolean;
  handleCancelPlanSwitch: () => void;
  handleConfirm: () => void;
  handleOpenSavePlanModal: () => void;
  handleSelectPlan: (planId: string) => void;
  handleImportClick?: () => void;
  syncThresholds: (projectId: string, siteId: string) => void;
  matrixDrift: boolean;
  checkingMatrix: boolean;
}> = ({
  handleSelectPlan,
  handleImportClick,
  syncThresholds,
  matrixDrift,
  checkingMatrix,
}) => {
  const role = useAppSelector(selectRole);
  const isGuest = roleIsGuest(role!);

  const { currentSite, availablePlans: plans } = useSubscribeToAvailablePlans();
  const dispatch = useAppDispatch();
  const latestSimulation = useAppSelector(selectLatestCurrentPlanSimulation);
  const tasksHaveBeenModified = useAppSelector(selectTaskModified);
  const currentPlanId = useAppSelector(selectCurrentPlanId);

  const [isOpenSimulate, setOpenSimulate] = useState(false);
  const [discardPlanId, setDiscardPlanId] = useState("");

  const currentPlan = plans ? plans[currentPlanId!] : null;

  // const { minorHashDrift } = matrixDrift || {};
  const simulationAlert = matrixDrift
    ? "Plan is out of sync with project Risk Matrix"
    : "";

  const simulationInProgress = [
    "pending",
    "site_pending",
    "partial",
    "prepared",
  ].includes(latestSimulation?.status || "");
  const simulationErrored = ["error"].includes(latestSimulation?.status || "");

  const simulationMessage = simulationInProgress ? (
    <span>In progress...</span>
  ) : tasksHaveBeenModified ? (
    <span>Unsaved task changes</span>
  ) : simulationErrored ? (
    <span className="text-red-500 flex flex-col">
      <span>Failed</span>
      <span>contact support@ehab.co</span>
    </span>
  ) : (
    <span className="flex gap-4">
      Simulated{" "}
      {latestSimulation?.createdAt
        ? moment(latestSimulation?.createdAt.toDate()).format(
            "Do MMM [at] kk:mm"
          )
        : ""}
      {latestSimulation?.createdAt &&
        (currentPlan as any)?.tasksUpdatedAt &&
        (currentPlan as any)?.tasksUpdatedAt >
          latestSimulation?.createdAt.toDate() && (
          <strong className="text-red-500">Out of date.</strong>
        )}
    </span>
  );

  return (
    <StyledPlanControls className="px-6">
      <div className="above-task-list relative">
        <span className="flex gap-4 items-center">
          <Button
            className="mr-0"
            type="button"
            aria-label="Simulate"
            isCompact
            alert={simulationAlert}
            alertDirection="left"
            disabled={
              simulationInProgress ||
              !currentPlanId ||
              isGuest ||
              tasksHaveBeenModified
            }
            onClick={async () => setOpenSimulate(true)}
          >
            <RiPlayLine className="inline relative bottom-1 text-3xl" />{" "}
            Simulate
          </Button>
          {currentPlanId ? (
            <span className="italic">{simulationMessage}</span>
          ) : null}
        </span>
      </div>

      <div className="above-gantt-chart">
        <Tabs isSimulating={simulationInProgress} />

        {currentSite && (
          <>
            {!isGuest && (
              <button
                className="mr-4 font-bold ml-auto cursor-pointer bg-[#1ceaa0] rounded-[5rem] border border-solid border-[#444444] inline-block text-[#444444] py-[0.4rem] px-8 no-underline hover:brightness-95 hover:disabled:brightness-100 hover:bg-[#44eeb0] active:relative active:top-[1px] disabled:opacity-40 disabled:cursor-default"
                onClick={() => handleImportClick && handleImportClick()}
                disabled={!handleImportClick}
              >
                Import
              </button>
            )}
            <PlanSelector
              plans={plans ? Object.values(plans) : []}
              currentSite={currentSite}
              selectedPlan={currentSite.plan}
              handleSelectPlan={handleSelectPlan}
              setMainPlan={(site: ISite, planId: string) =>
                dispatch(setAsMainPlan(site, planId))
              }
              setDiscardPlanModal={(planId: string) => {
                setDiscardPlanId(planId);
              }}
            />
          </>
        )}
      </div>

      {isOpenSimulate && (
        <SimulationModal
          setOpenSimulate={setOpenSimulate}
          planId={currentPlanId!}
          siteId={currentSite!.id}
          projectId={currentSite!.project}
          syncThresholds={syncThresholds}
          riskMatrixDrift={matrixDrift}
          checkingMatrix={checkingMatrix}
        />
      )}
      {discardPlanId && (
        <DiscardAlert
          message="Are you sure you want to delete the plan?"
          handleCancel={() => setDiscardPlanId("")}
          handleDiscard={() => {
            discardPlan(discardPlanId);
            setDiscardPlanId("");
          }}
        ></DiscardAlert>
      )}
    </StyledPlanControls>
  );
};

export default PlanControls;
