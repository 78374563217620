import {
  ISite,
  ITaskCategory,
  WeatherKey,
} from "@ehabitation/ts-utils/browser";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { TableCell, TableRow } from "ui-shadcn/table";
import { ISiteHeatmapData } from "../CategoryHeatmaps/SingleCategoryHeatmap";
import { useGetHeatmap } from "../CategoryHeatmaps/hooks";
import { SingleRowHeatmap } from "../CategoryHeatmaps/singlerow-heatmap";
import ActivityCategoryInputs from "./ActivityCategoryInputs";
import AddThresholdSelect from "./AddThresholdSelect";
import ShiftInputSection from "./ShiftInputSection";
import ThresholdList from "./ThresholdList";

interface CategoryThresholdsRowNewNew {
  category: ITaskCategory;
  changeMap: Record<string, any>;
  updateChangeMap: (change: Record<string, any>) => void;
  readOnly: boolean;
  hasVisibility: boolean;
  site: ISite;
}

const readableThresholdNames: Record<WeatherKey, string> = {
  minTemp: "Min Daily Temp (°C)",
  maxTemp: "Max Daily Temp (°C)",
  wind: "Wind Speed (m/s)",
  windGusts: "Wind Gusts (m)",
  dailyRainAcc: "Daily Cumulative Rainfall (mm)",
  hourlyRainAcc: "Max Hourly Rainfall (mm/h)",
  snowfall24Hour: "Daily Cumulative Snowfall (mm)",
  snowfall: "Max Hourly Snowfall (mm/h)",
  waveHeight: "Wave Height (m)",
  visibility: "Visibility (km)",
};

const CategoryThresholdsRowNew: FC<CategoryThresholdsRowNewNew> = ({
  category,
  changeMap,
  updateChangeMap,
  readOnly,
  hasVisibility,
  site,
}) => {
  const { level, categoryCode, activityCode, name, thresholds, shift, id } =
    category;

  const activeThresholds = changeMap[id] || thresholds;
  const activeCategoryCode = changeMap[id]?.categoryCode || categoryCode;
  const activeActivityCode = changeMap[id]?.activityCode || activityCode;
  const activeShift = changeMap[id]?.shift || shift;

  const columns: WeatherKey[] = useMemo(
    () => [
      WeatherKey.minTemp,
      WeatherKey.maxTemp,
      WeatherKey.wind,
      WeatherKey.windGusts,
      WeatherKey.dailyRainAcc,
      WeatherKey.hourlyRainAcc,
      WeatherKey.snowfall24Hour,
      WeatherKey.snowfall,
      WeatherKey.waveHeight,
      ...(hasVisibility ? [WeatherKey.visibility] : []),
    ],
    [hasVisibility]
  );

  const [displayedColumns, setDisplayedColumns] = useState<WeatherKey[]>(
    columns.filter(
      (col) =>
        activeThresholds[col] !== undefined && activeThresholds[col] !== null
    )
  );

  const [heatmap, setHeatmap] = useState<ISiteHeatmapData>();
  const { fetchHeatmap } = useGetHeatmap(setHeatmap);

  // Fetch heatmap on initial load
  useEffect(() => {
    if (site && site.status === "open" && category && Object.keys(category).length > 0) {
      const cleanedThresholds = cleanThresholds(activeThresholds);
      const category_for_heatmap = {
        id: category.id,
        name: category.name,
        level: category.level,
        shift: activeShift ? activeShift : "24hr",
        thresholds: cleanedThresholds,
      };
      fetchHeatmap(site.id, category_for_heatmap);
    }
  }, [site, category, activeThresholds, activeShift, fetchHeatmap]);

  function cleanThresholds(thresholds: any) {
    // Exclude specific keys and remove null values
    const { activityCode, categoryCode, shift, ...rest } = thresholds;

    return Object.fromEntries(
      Object.entries(rest).filter(([key, value]) => value !== null)
    );
  }

  const createHandleChange = useCallback(
    (
      field: WeatherKey | "categoryCode" | "activityCode" | "shift",
      value: any
    ) => {
      const updatedThresholds = {
        ...activeThresholds,
        categoryCode: field === "categoryCode" ? value : activeCategoryCode,
        activityCode: field === "activityCode" ? value : activeActivityCode,
        shift: field === "shift" ? value : activeShift,
        [field]: value,
      };

      updateChangeMap({
        ...changeMap,
        [id]: updatedThresholds,
      });

      if (
        field !== "categoryCode" &&
        field !== "activityCode" &&
        field !== "shift" &&
        (value === null || value === "")
      ) {
        setDisplayedColumns((prev) => prev.filter((col) => col !== field));
      } else if (
        field !== "categoryCode" &&
        field !== "activityCode" &&
        field !== "shift" &&
        !displayedColumns.includes(field as WeatherKey)
      ) {
        setDisplayedColumns((prev) => [...prev, field as WeatherKey]);
      }
    },
    [
      updateChangeMap,
      changeMap,
      id,
      activeThresholds,
      activeCategoryCode,
      activeActivityCode,
      activeShift,
      displayedColumns,
    ]
  );

  const handleRemoveThreshold = (threshold: WeatherKey) => {
    createHandleChange(threshold, null); // Set the value to null
    setDisplayedColumns((prev) => prev.filter((col) => col !== threshold));
  };

  const [selectedThreshold, setSelectedThreshold] = useState<WeatherKey | "">(
    ""
  );

  const [showMappingInputs, setShowMappingInputs] = useState<boolean>(
    activeCategoryCode || activeActivityCode
  );

  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  const availableColumns = useMemo(
    () => columns.filter((col) => !displayedColumns.includes(col)),
    [columns, displayedColumns]
  );

  return (
    <>
      <TableRow>
        <TableCell
          className="text-2xl cursor-pointer align-top"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          {isCollapsed ? <>+</> : <>-</>}
        </TableCell>
        <TableCell className="text-2xl  align-top">{name}</TableCell>
        <TableCell className="w-[100px] align-top ">
          {isCollapsed ? (
            <>{activeShift ? activeShift : "24hr"}</>
          ) : (
            <ShiftInputSection
              id={id}
              shift={activeShift}
              handleChange={createHandleChange}
              changeMap={changeMap}
              readOnly={readOnly}
            />
          )}
        </TableCell>
        <TableCell className="align-middle ">
          {isCollapsed ? (
            <>
              {activeActivityCode
                ? activeActivityCode + " (Activity Code)"
                : activeCategoryCode
                ? activeCategoryCode + " (EHAB_CATEGORY UDF)"
                : ""}
            </>
          ) : (
            <ActivityCategoryInputs
              id={id}
              activityCode={activeActivityCode}
              categoryCode={activeCategoryCode}
              handleChange={createHandleChange}
              showMappingInputs={showMappingInputs}
              setShowMappingInputs={setShowMappingInputs}
              changeMap={changeMap}
              readOnly={readOnly}
            />
          )}
        </TableCell>
        <TableCell className="">
          {displayedColumns.length} thresholds set
        </TableCell>
        <TableCell>
          <SingleRowHeatmap
            data={
              heatmap && heatmap[category.name]?.combined_success_ratio
                ? heatmap[category.name]?.combined_success_ratio
                : []
            }
            title={""}
          />
        </TableCell>
      </TableRow>
      {!isCollapsed && (
        <>
          <ThresholdList
            id={id}
            thresholds={thresholds}
            displayedColumns={displayedColumns}
            handleThresholdChange={createHandleChange}
            handleRemoveThreshold={handleRemoveThreshold}
            changeMap={changeMap}
            activeThresholds={activeThresholds}
            readOnly={readOnly}
            readableThresholdNames={readableThresholdNames}
            category={category}
            heatmap={heatmap}
          />
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell className="w-[300px] pb-28">
              <AddThresholdSelect
                id={id}
                selectedThreshold={selectedThreshold}
                setSelectedThreshold={setSelectedThreshold}
                handleThresholdChange={createHandleChange}
                availableColumns={availableColumns}
                readableThresholdNames={readableThresholdNames}
                activeThresholds={activeThresholds}
                readOnly={readOnly}
              />
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </>
      )}
    </>
  );
};

CategoryThresholdsRowNew.displayName = "CategoryThresholdsRowNew";

export default CategoryThresholdsRowNew;
