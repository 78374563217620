export const thresholdAlert =
  "Project thresholds for this category have changed. Please review the thresholds below, or re-assign the category.";

export const thresholdAlertBeforeSimulate =
  "Weather impact thresholds have been modified. This means the weather-related risks for some activities may have changed.";

export const simulateConfirmation =
  "Simulating will reset all risk data and selections. Are you sure you want to continue?";

export const applyNewThresholdsToPlan = 
  "Apply new thresholds to plan before simulating. Note this change is irreversible."

export const simulateUsingPlanThresholds = 
  "Simulate using previous thresholds."

export const clickToSeeThresholds = 
  "Click here to see project and plan threholds."


